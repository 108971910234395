.react-daterange-picker__wrapper {
    border: none;
}
.react-daterange-picker__inputGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-daterange-picker__inputGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}