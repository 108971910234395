@font-face {
  font-family: NexaRegular;
  src: url(./assets/fonts/Nexa-Regular.otf)
}

@font-face {
  font-family: NexaBold;
  src: url(./assets/fonts/Nexa-Bold.otf)
}

@font-face {
  font-family: NexaLight;
  src: url(./assets/fonts/Nexa-Light.otf)
}

body, html {
  font-family: NexaRegular !important;
}

#root {
  font-family: NexaRegular !important;
}

.root {
  font-family: NexaRegular !important;
}

h1, h2, h3, h4, h5, h6, p, a, span {
  font-family: NexaRegular !important;
}